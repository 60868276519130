// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBsBkNK8wRB-KV4vHUa-y9wprctbwxD-Y0",
  authDomain: "farmingseo13.firebaseapp.com",
  projectId: "farmingseo13",
  storageBucket: "farmingseo13.appspot.com",
  messagingSenderId: "259814434187",
  appId: "1:259814434187:web:4518e53671d19a1f8d7b8e"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);

const auth = getAuth(app);

export default auth;